import React from "react";
import { Map, GoogleApiWrapper, HeatMap, withGoogleMap, GoogleMap, Marker } from "google-maps-react";

//npm install --save google-maps-react --force
//https://developers.google.com/maps/documentation/javascript/libraries-open-source

import "./style.css";
import ReactDOM from 'react-dom';

function clickMarker(value) {

  //API REQUEST WAIT UNTILL RESPONDED TO MOVE FORWARD
  function httpRequest(address, reqType, asyncProc) {
    var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
    if (asyncProc) { 
      req.onreadystatechange = function() { 
        if (this.readyState == 4) {
          asyncProc(this);
        } 
      };
    }
    var data = typeof window !== "undefined" ? new FormData() : ""
    typeof window !== "undefined" ? data.append("location", value) : ""
    typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
    typeof window !== "undefined" ? req.send(data) : ""

  return req;
  }
  var req = httpRequest("https://api.checksolarcost.com/v1/getContextHospitality", "POST");
  const response = req.responseText;
  const response_obj = typeof window !== "undefined" ? JSON.parse(response) : ""
  
  //alert(response_obj['image']);

  ReactDOM.render(
  
    <div>
      <a class="button" href="#popup" id="popup_window_click"></a>
      <div class="popup" id="popup">
        <div class="popup-inner">
          <div class="popup__photo">
            <img src={response_obj['image']}></img>
          </div>
          <div class="popup__text">
            <h1>{value}</h1>
            <p>{response_obj['context']}</p>
          </div>
          <a class="popup__close" href="#">X</a>
        </div>
      </div>
    </div>
  
  , document.getElementById('popup_window'));

  document.getElementById('popup_window_click').click();

}

//https://api.checksolarcost.com/v1/getLoactionsHospitality

//API REQUEST WAIT UNTILL RESPONDED TO MOVE FORWARD
function httpRequest(address, reqType, asyncProc) {
  var req = typeof window !== "undefined" ? new XMLHttpRequest() : ""
  if (asyncProc) { 
    req.onreadystatechange = function() { 
      if (this.readyState == 4) {
        asyncProc(this);
      } 
    };
  }
  typeof window !== "undefined" ? req.open(reqType, address, !(!asyncProc)) : ""
  typeof window !== "undefined" ? req.send() : ""
return req;
}
var req = httpRequest("https://api.checksolarcost.com/v1/getLoactionsHospitality", "POST");
const response = req.responseText;
const response_obj = typeof window !== "undefined" ? JSON.parse(response) : ""
const markers = typeof window !== "undefined" ? response_obj.map(item => {return (

  <Marker
    position={{ lat: item['lat'], lng: item['lng'] }}
    onClick={() => clickMarker(item['location'])}
  />

  )}) : ""


class MapContainer extends React.Component {
  render() {
    const gradient = [
      "rgba(0, 255, 255, 0)",
      "rgba(0, 255, 255, 1)",
      "rgba(0, 191, 255, 1)",
      "rgba(0, 127, 255, 1)",
      "rgba(0, 63, 255, 1)",
      "rgba(0, 0, 255, 1)",
      "rgba(0, 0, 223, 1)",
      "rgba(0, 0, 191, 1)",
      "rgba(0, 0, 159, 1)",
      "rgba(0, 0, 127, 1)",
      "rgba(63, 0, 91, 1)",
      "rgba(127, 0, 63, 1)",
      "rgba(191, 0, 31, 1)",
      "rgba(255, 0, 0, 1)"
    ];
    return (
      <div className="map-container">
        <Map
          google={this.props.google}
          className={"map"}
          zoom={4.5}
          heigh="300px"
          initialCenter={this.props.center}
          onReady={this.handleMapReady}
          
        >
          {/*
            <HeatMap
              gradient={gradient}
              positions={this.props.data}
              opacity={12}
              radius={10}
            >
          */}
        { markers }

        </Map>

        <div class="container" id="popup_window"></div>

      </div>
    );
  }
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyAEUA8nRhSd10_ezuWEMEV5TwELbbuwxf4",
  libraries: ["visualization"]
})(MapContainer);
